@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FE0201;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #294378;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
}

.full-page-loader {
    position: fixed;
    top: 96px;
    z-index: 99999999999;
    background: white;
    bottom: 0;
    right: 0;
    left: 0;
}

.intercom-app div[style="opacity: 1;"] {
    opacity: 0 !important;
}

@media (min-width: 426px) {
    .intercom-messenger-frame {
        right: 0px !important;
        top: 96px !important;
        width: 100% !important;
        border-radius: 0px !important;
        transition: none !important;
        transform-origin: center !important;
        box-shadow: none !important;
        max-height: 100% !important;
    }
    .hideBadge {
        position: fixed;
        bottom: 173.1px;
        height: 32px;
        width: 200px;
        max-width: 100%;
        background: white;
        z-index: 99999999999;
        display: none;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}